export const sections = [
 {
    name: 'Condiciones ambientales de su centro de trabajo',
    instructions: 'Para responder las preguntas siguientes considere LAS CONDICIONES AMBIENTALES DE SU CENTRO DE TRABAJO. (considere únicamente los últimos 2 meses)',
    questionNumbers: [1, 2, 3, 4, 5],
  },
  {
    name: 'Cantidad y ritmo de trabajo 2', 
    instructions: 'Para responder a las preguntas siguientes piense en LA CANTIDAD Y RITMO DE TRABAJO que tiene. (considere únicamente los últimos 2 meses)',
    questionNumbers: [6, 7, 8],
  },
  {
    name: '3',
    instructions: 'Las preguntas siguientes están relacionadas con EL ESFUERZO MENTAL QUE LE EXIGE SU TRABAJO. (considere únicamente los últimos 2 meses)',
    questionNumbers: [9, 10, 11,12],
  },
  {
    name: '4',
    instructions: 'Las preguntas siguientes están relacionadas con LAS ACTIVIDADES QUE REALIZA EN SU TRABAJO Y LAS RESPONSABILIDADES QUE TIENE. (considere únicamente los últimos 2 meses)',
    questionNumbers: [13,14,15,16],
  },
  {
    name: 'seccion 5',
    instructions: 'Las preguntas siguientes están relacionadas con SU JORNADA DE TRABAJO. (considere únicamente los últimos 2 meses)',
    questionNumbers: [17,18,19,20,21,22],
  },
  {
    name: 'seccion 6',
    instructions: 'Las preguntas siguientes están relacionadas CON LAS DECISIONES QUE PUEDE TOMAR EN SU TRABAJO',
    questionNumbers: [23,24,25,26,27,28],
  },
  {
    name: 'seccion 7',
    instructions: 'Las preguntas siguientes están relacionadas con CUALQUIER TIPO DE CAMBIO QUE OCURRA EN SU TRABAJO (considere los últimos cambios realizados).',
    questionNumbers: [29,30],
  },
  {
    name: 'seccion 8 ',
    instructions: 'Las preguntas siguientes están relacionadas con LA CAPACITACIÓN E INFORMACIÓN QUE RECIBE SOBRE SU TRABAJO.',
    questionNumbers: [31,32,33,34,35,36],
  },
  {
    name: 'seccion 9 ',
    instructions: 'Las preguntas siguientes están relacionadas con EL O LOS JEFES CON QUIEN TIENE CONTACTO.  ',
    questionNumbers: [37,38,39,40,41],
  },
  {
    name: 'seccion 10  ',
    instructions: 'Las preguntas siguientes se refieren A LAS RELACIONES CON SUS COMPAÑEROS. (COMPAÑEROS DE ÁREA O DEPARTAMENTO) (considere únicamente los últimos 2 meses)',
    questionNumbers: [42,43,44,45,46],
  },
  {
    name: 'seccion 11 ',
    instructions: 'Las preguntas siguientes están relacionadas con LA INFORMACIÓN QUE RECIBE SOBRE SU RENDIMIENTO EN EL TRABAJO, EL RECONOCIMIENTO, EL SENTIDO DE PERTENENCIA Y LA ESTABILIDAD QUE LE OFRECE SU TRABAJO. ',
    questionNumbers: [47,48,49,50,51,52,53,54,55,56],
  },
  {
    name: 'seccion 12 ',
    instructions: 'Las preguntas siguientes están relacionadas con ACTOS DE VIOLENCIA LABORAL (MALOS TRATOS, ACOSO, HOSTIGAMIENTO, ACOSO PSICOLÓGICO).  (considere únicamente los últimos 2 meses)',
    questionNumbers: [57,58,59,60,61,62,63,64],
  },
  
    {name: 'seccion 13 ',
    instructions: 'Las preguntas siguientes están relacionadas con LA ATENCIÓN A CLIENTES Y USUARIOS. (CONTESTE NUNCA SI NO BRINDA SERVICIO A CLIENTES O USUARIOS) (considere únicamente los últimos 2 meses)',
    questionNumbers: [65,66,67,68],
  },
  {
    name: 'seccion 14 ',
    instructions: 'Las preguntas siguientes están relacionadas CON LAS ACTITUDES DE LAS PERSONAS QUE SUPERVISA. (conteste NUNCA si NO es jefe de otros trabajadores)  (considere únicamente los últimos 2 meses)',
    questionNumbers: [69,70,71,72],
  },


  // Add more sections here...
];

export const questions = [
            {
              number: 1,
              text:
                "El espacio donde trabajo me permite realizar mis actividades de manera segura e higiénica",
              options: [
                { value: 0, label: "Siempre" },
                { value: 1, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 3, label: "Casi nunca" },
                { value: 4, label: "Nunca" },
              ],
            },
            {
              number: 2,
              text:
                "Considero que en mi trabajo se aplican las normas de seguridad y salud en el trabajo",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 3,
              text: "Mi trabajo me exige hacer mucho esfuerzo físico",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 4,
              text:
                "Considero que en mi trabajo se aplican las normas de seguridad y salud en el trabajo",
              options: [
                { value: 0, label: "Siempre" },
                { value: 1, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 3, label: "Casi nunca" },
                { value: 4, label: "Nunca" },
              ],
            },
            {
              number: 5,
              text: "Considero que las actividades que realizo son peligrosas",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 6,
              text: "Por la cantidad de trabajo que tengo debo quedarme tiempo adicional a mi turno",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 7,
              text: "Por la cantidad de trabajo que tengo debo trabajar sin parar",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
            ],
        },{
            number: 8,
            text: "Considero que es necesario mantener un ritmo de trabajo acelerado",
            options: [
              { value: 4, label: "Siempre" },
              { value: 3, label: "Casi siempre" },
              { value: 2, label: "Algunas veces" },
              { value: 1, label: "Casi nunca" },
              { value: 0, label: "Nunca" },
            ],
          },
            {
              number: 9,
              text: "Mi trabajo exige que esté muy concentrado",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 10,
              text: "Mi trabajo requiere que memorice mucha información",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 11,
              text: "En mi trabajo tengo que tomar decisiones difíciles muy rápido",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 12,
              text: "Mi trabajo exige que atienda varios asuntos al mismo tiempo",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 13,
              text: "En mi trabajo soy responsable de cosas de mucho valor",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 14,
              text: "Respondo ante mi jefe por los resultados de toda mi área de trabajo",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 15,
              text: "En mi trabajo me dan órdenes contradictorias",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            },
            {
              number: 16,
              text: "Considero que en mi trabajo me piden hacer cosas innecesarias",
              options: [
                { value: 4, label: "Siempre" },
                { value: 3, label: "Casi siempre" },
                { value: 2, label: "Algunas veces" },
                { value: 1, label: "Casi nunca" },
                { value: 0, label: "Nunca" },
              ],
            }, {
                number: 17,
                text: "Trabajo horas extras más de tres veces a la semana",
                options: [
                  { value: 4, label: "Siempre" },
                  { value: 3, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 1, label: "Casi nunca" },
                  { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 18,
                text: "Mi trabajo me exige laborar en días de descanso, festivos o fines de semana",
                options: [
                  { value: 4, label: "Siempre" },
                  { value: 3, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 1, label: "Casi nunca" },
                  { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 19,
                text: "Considero que el tiempo en el trabajo es mucho y perjudica mis actividades familiares o personales",
                options: [
                  { value: 4, label: "Siempre" },
                  { value: 3, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 1, label: "Casi nunca" },
                  { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 20,
                text: "Debo atender asuntos de trabajo cuando estoy en casa",
                options: [
                  { value: 4, label: "Siempre" },
                  { value: 3, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 1, label: "Casi nunca" },
                  { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 21,
                text: "Pienso en las actividades familiares o personales cuando estoy en mi trabajo",
                options: [
                  { value: 4, label: "Siempre" },
                  { value: 3, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 1, label: "Casi nunca" },
                  { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 22,
                text: "Pienso que mis responsabilidades familiares afectan mi trabajo",
                options: [
                  { value: 4, label: "Siempre" },
                  { value: 3, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 1, label: "Casi nunca" },
                  { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 23,
                text: "Mi trabajo permite que desarrolle nuevas habilidades",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 24,
                text: "En mi trabajo puedo aspirar a un mejor puesto",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 25,
                text: "Durante mi jornada de trabajo puedo tomar pausas cuando las necesito",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 26,
                text: "Puedo decidir cuánto trabajo realizo durante la jornada laboral",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 27,
                text: "Puedo decidir la velocidad a la que realizo mis actividades en mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 28,
                text: "Puedo cambiar el orden de las actividades que realizo en mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
   
              {
                number: 29,
                text: "Los cambios que se presentan en mi trabajo dificultan mi labor",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 30,
                text: "Cuando se presentan cambios en mi trabajo se tienen en cuenta mis ideas o aportaciones",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 31,
                text: "Me informan con claridad cuáles son mis funciones",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 32,
                text: "Me explican claramente los resultados que debo obtener en mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 33,
                text: "Me explican claramente los objetivos de mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 34,
                text: "Me informan con quién puedo resolver problemas o asuntos de trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 35,
                text: "Me permiten asistir a capacitaciones relacionadas con mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 36,
                text: "Recibo capacitación útil para hacer mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 37,
                text: "Mi jefe ayuda a organizar mejor el trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 38,
                text: "Mi jefe tiene en cuenta mis puntos de vista y opiniones",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 39,
                text: "Mi jefe me comunica a tiempo la información relacionada con el trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 40,
                text: "La orientación que me da mi jefe me ayuda a realizar mejor mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 41,
                text: "Mi jefe ayuda a solucionar los problemas que se presentan en el trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 42,
                text: "Puedo confiar en mis compañeros de trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 43,
                text: "Entre compañeros solucionamos los problemas de trabajo de forma respetuosa",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 44,
                text: "En mi trabajo me hacen sentir parte del grupo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 45,
                text: "Cuando tenemos que realizar trabajo en equipo, los compañeros colaboran",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 46,
                text: "Mis compañeros de trabajo me ayudan cuando tengo dificultades",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 47,
                text: "Me informan sobre lo que hago bien en mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 48,
                text: "La forma como evalúan mi trabajo en mi centro de trabajo me ayuda a mejorar mi desempeño",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 49,
                text: "En mi centro de trabajo me pagan a tiempo mi salario",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 50,
                text: "El pago que recibo es el que merezco por el trabajo que realizo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 51,
                text: "Si obtengo los resultados esperados en mi trabajo me recompensan o reconocen",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 52,
                text: "Las personas que hacen bien el trabajo pueden crecer laboralmente",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 53,
                text: "Considero que mi trabajo es estable",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 54,
                text: "En mi trabajo existe continua rotación de personal",
                options: [
                  { value: 4, label: "Siempre" },
                  { value: 3, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 1, label: "Casi nunca" },
                  { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 55,
                text: "Siento orgullo de laborar en este centro de trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 56,
                text: "Me siento comprometido con mi trabajo",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 57,
                text: "En mi trabajo puedo expresarme libremente sin interrupciones",
                options: [
                  { value: 0, label: "Siempre" },
                  { value: 1, label: "Casi siempre" },
                  { value: 2, label: "Algunas veces" },
                  { value: 3, label: "Casi nunca" },
                  { value: 4, label: "Nunca" },
                ],
              },
              {
                number: 58,
                text: "Recibo críticas constantes a mi persona y/o trabajo",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 59,
                text: "Recibo burlas, calumnias, difamaciones, humillaciones o ridiculizaciones",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 60,
                text: "Se ignora mi presencia o se me excluye de las reuniones de trabajo y en la toma de decisiones",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 61,
                text: "Se manipulan las situaciones de trabajo para hacerme parecer un mal trabajador",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 62,
                text: "Se ignoran mis éxitos laborales y se atribuyen a otros trabajadores",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 63,
                text: "Me bloquean o impiden las oportunidades que tengo para obtener ascenso o mejora en mi trabajo",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 64,
                text: "He presenciado actos de violencia en mi centro de trabajo",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 65,
                text: "Atiendo clientes o usuarios muy enojados",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 66,
                text: "Mi trabajo me exige atender personas muy necesitadas de ayuda o enfermas",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 67,
                text: "Para hacer mi trabajo debo demostrar sentimientos distintos a los míos",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 68,
                text: "Mi trabajo me exige atender situaciones de violencia",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 69,
                text: "Comunican tarde los asuntos de trabajo",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 70,
                text: "Dificultan el logro de los resultados del trabajo",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 71,
                text: "Cooperan poco cuando se necesita",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
              {
                number: 72,
                text: "Ignoran las sugerencias para mejorar su trabajo",
                options: [
                    { value: 4, label: "Siempre" },
                    { value: 3, label: "Casi siempre" },
                    { value: 2, label: "Algunas veces" },
                    { value: 1, label: "Casi nunca" },
                    { value: 0, label: "Nunca" },
                ],
              },
         
  ];
  
  
