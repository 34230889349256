// Import required packages and styles
import React, { useState } from 'react';
import axios from '../utils/api';
import '../styles.css';
import logo from '../assets/asc-logo.png';

const LoginForm = ({ onLogin }) => {
  // State variables for form inputs
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [centroTrabajo, setCentroTrabajo] = useState('ASC');
  const [errorMessage, setErrorMessage] = useState(null);
  const [respuestas, setRespuestas] = useState(null);

  // Function to handle form submission
  const handleSubmit = (e) => {

      // Check if employeeNumber is empty
      if (!employeeNumber) {
        setErrorMessage("Verifica tu clave de cuestionario");
        return;
      }
  
    // Create the request body with employeeNumber and centroTrabajo
    const data = {
      employeeNumber,
      centroTrabajo,
    };

    // Send a POST request to the server for employee login
    axios
    .post('/api/login', data)
      .then((response) => {

         // Clear any existing error message
         setErrorMessage(null);

        // Call the onLogin callback function with the retrieved employee data
        setRespuestas(response.data);

          // call the onLogin function here, so it updates the App's state
          //console.log("Login response data:", response.data);

      onLogin(response.data.employee);  // <-- Pass the employee data here
    })
    .catch((error) => {
       // Handle error (e.g., employee not found)
        console.error(error);
        setErrorMessage("Verifica tu clave de cuestionario");
      });
};


  return (
    <div className="container">
      <div className="form-header">
      <img src={logo} alt="Logo" className="logo" />
        <h2>IDENTIFICACIÓN DE LOS FACTORES DE RIESGO PSICOSOCIAL Y EVALUACIÓN DEL ENTORNO ORGANIZACIONAL</h2>
    

      <div className="test-form">
        <div>

<h4>Hola, te damos la bienvenida al cuestionario,</h4> 

<p>
   Este cuestionario tiene como objetivo identificar y analizar de forma general los factores de riesgo psicosocial y evaluar el entorno organizacional en el que desarrollamos nuestras actividades. 
   </p>
   <p>Antes de comenzar, es importante que sigas estas instrucciones:</p>
<ol>

<li>Asegúrate de que las condiciones de las instalaciones y el mobiliario sean adecuadas.</li>
<li>Procura mantener tu concentración durante todo el cuestionario. (La duración estimada es de 40 minutos)</li>
</ol>
<p>Ten en cuenta lo siguiente durante el cuestionario:</p>

<ul>
<li>No existen respuestas correctas o incorrectas; </li>
<li>Considera únicamente las condiciones o sucesos de los <b>últimos 2 meses.</b></li>
<li>Tu opinión es lo más importante, así que te pedimos respondas con <b>sinceridad.</b></li>
<li>La información que nos proporciones será utilizada para mejorar el ambiente de trabajo.</li>
</ul>

<p>Para comenzar, ingresa tu clave de cuestionario y el centro de trabajo al que perteneces. </p>
</div>


          <div>
            <label htmlFor="employeeNumber"><b>Clave de cuestionario</b></label>
            <input
              type="text"
              id="employeeNumber"
              value={employeeNumber}
              onChange={(e) => setEmployeeNumber(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="centroTrabajo"><b>Centro de trabajo</b></label>
            <select
              id="centroTrabajo"
              value={centroTrabajo}
              onChange={(e) => setCentroTrabajo(e.target.value)}
              required
            >
              <option value="">Seleccione el centro de trabajo</option>
              <option value="ASC">ASC</option>
            </select>
            
          </div>
          {errorMessage && <div className='error'>{errorMessage}</div>} {/* <-- Render error message here */}
          <br></br>
          <button onClick={() => {
            handleSubmit(); 
            window.scrollTo(0, 0);
          }}
          >Iniciar</button>
      </div>
    </div>
    </div>
  );
};

export default LoginForm;
