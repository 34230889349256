import React, { useState, useEffect } from 'react';
import axios from '../utils/api';
import { sections, questions } from './questionsData';
import '../styles.css';
import logo from '../assets/asc-logo.png';

const TestForm = ({ responses, user }) => {
  // State variables
  const [answers, setAnswers] = useState(Array(questions.length).fill(''));
  const [userData, setUserData] = useState({
    employeeNumber: '',
    name: '',
    email: '',
    position: '',
    department: '',
    suggestions: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const [invalid, setInvalid] = useState(false);
  const topRef = React.useRef(null);

  // Function to handle answer changes
  const handleAnswerChange = (questionIndex, value) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[questionIndex] = Number(value); // Convert value to a number
      return newAnswers;
    });
  };
  
  // Function to handle user data changes
  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!invalid) {
      alert('Por favor llena todos los campos');
      return;
    }

    const score = answers.reduce((total, answer) => total + answer, 0);
    const currentDate = new Date().toISOString();

    //console.log("User prop in TestForm:", user);
  //console.log("employeeNumber in TestForm:", user?.employeeNumber);


    const data = {
      answers,
      score,
      userData: {
        ...user.userData,
        suggestions: userData.suggestions, 
      },
      submissionDate: currentDate,
    };
    // Inside the handleSubmit function
   // console.log("Data being sent to server: ", data);

    axios
      .post('/api/responses', data)
      .then((response) => {
        //console.log(response.data);
        setSubmitted(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Function to calculate the final score
  const calculateFinalScore = () => {
    const finalScore = answers.reduce((total, answer) => total + answer, 0);
    return finalScore;
  };

  useEffect(() => {
    // Validation logic for each step
    const { employeeNumber, name, position, department } = userData;
    setInvalid(validationLiteral[step]());
  }, [answers, step]);

  // Object containing validation functions for each step
  const validationLiteral = {
    0: () => {
      return (
        answers[0] === '' ||
        answers[1] === '' ||
        answers[2] === '' ||
        answers[3] === '' ||
        answers[4] === ''
      );
    },
    1: () => {
      return (
        answers[5] === '' ||
        answers[6] === '' ||
        answers[7] === ''
      );
    },
    2: () => {
      return (
        answers[8] === '' ||
        answers[9] === '' ||
        answers[10] === '' ||
        answers[11] === ''
      );
    },
    3: () => {
      return (
        answers[12] === '' ||
        answers[13] === '' ||
        answers[14] === '' ||
        answers[15] === ''
      );
    },
    4: () => {
      return (
        answers[16] === '' ||
        answers[17] === '' ||
        answers[18] === '' ||
        answers[19] === '' ||
        answers[20] === '' ||
        answers[21] === ''
      );
    },
    5: () => {
      return (
        answers[22] === '' ||
        answers[23] === '' ||
        answers[24] === '' ||
        answers[25] === '' ||
        answers[26] === '' ||
        answers[27] === ''
      );
    },
    6: () => {
      return (
        answers[28] === '' ||
        answers[29] === ''
      );
    },
    7: () => {
      return (
        answers[30] === '' ||
        answers[31] === '' ||
        answers[32] === '' ||
        answers[33] === '' ||
        answers[34] === '' ||
        answers[35] === ''
      );
    },
    8: () => {
      return (
        answers[36] === '' ||
        answers[37] === '' ||
        answers[38] === '' ||
        answers[39] === '' ||
        answers[40] === '' 
        );
      },
      9: () => {
        return (
        answers[41] === '' ||
        answers[42] === '' ||
        answers[43] === '' ||
        answers[44] === '' ||
        answers[45] === '' 
        );
      },
      10: () => {
        return (
        answers[46] === '' ||
        answers[47] === '' ||
        answers[48] === '' ||
        answers[49] === '' ||
        answers[50] === '' ||
        answers[51] === '' ||
        answers[52] === '' ||
        answers[53] === '' ||
        answers[54] === '' ||
        answers[55] === ''
      );
    },
    11: () => {
      return (
        answers[56] === '' ||
        answers[57] === '' ||
        answers[58] === '' ||
        answers[59] === '' ||
        answers[60] === '' ||
        answers[61] === '' ||
        answers[62] === '' ||
        answers[63] === '' 
      );
    },
    12: () => {
      return (
        answers[64] === '' ||
        answers[65] === '' ||
        answers[66] === '' ||
        answers[67] === '' 
      );
    },
    13: () => {
      return (
        answers[68] === '' ||
        answers[69] === '' ||
        answers[70] === '' ||
        answers[71] === '' 
      );
    },
    14: () => {
      const { department, position, name } = userData;
      return department === '' || position === '' || name === '';
    },
  };

  return (
    <div className="container">
      <div className="form-header">
        <a href="/"> <img src={logo} alt="Logo" className="logo" /></a>
        <h2>IDENTIFICACIÓN DE LOS FACTORES DE RIESGO PSICOSOCIAL Y EVALUACIÓN DEL ENTORNO ORGANIZACIONAL</h2>
        <p>Instrucciones: contesta las siguientes preguntas llenando únicamente 1 opción por pregunta</p>
        <div ref={topRef}>
      <div className="test-form">
        {submitted ? (
          <SuccessMessage />
        ) : (
          <form onSubmit={handleSubmit}>
            {/* Render sections and questions */}
            {sections.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                {step === sectionIndex && <h4>{section.instructions}</h4>}
                {section.questionNumbers.map((questionNumber) => {
                  const question = questions.find((q) => q.number === questionNumber);
                  return step === sectionIndex ? (
                    <div key={question.number}>
                      <p><b>{question.number}.-{question.text}</b></p>
                      <div className="question-options">
                        {/* Render question options */}
                        {question.options.map((option, optionIndex) => (
                          <label key={optionIndex}>
                            <input
                              type="radio"
                              name={`question-${questionNumber}`}
                              value={option.value}
                              checked={answers[questionNumber - 1] === option.value}
                              onChange={() => handleAnswerChange(questionNumber - 1, option.value)}
                            />
                            {option.label}
                          </label>
                        ))}
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            ))}

            {step === 14 && (
              <>
                {/* Render user data fields */}
                <div>

              <label htmlFor="suggestions">SUGERENCIAS O COMENTARIOS PARA TU EMPRESA </label>
              <textarea
                id="suggestions"
                name="suggestions"
                value={userData.suggestions}
                onChange={handleUserDataChange}
              />
            </div>
            <div>
              <p>Sus sugerencias serán reportadas <b> ANÓNIMAMENTE </b></p>
              <br></br>
            </div>

                {/* Add more input fields for user data */}
                

                <button type="submit">Enviar</button>
              </>
            )}
          </form>
        )}
<div className="progress-bar">
  <div className="progress-bar-fill" style={{ width: `${(step / 14) * 100}%` }}></div>
</div>
        {step !== 14 && (
          <div>
            {/* Navigation buttons */}
            {step > 0 && (
            <button onClick={() => setStep(step === 0 ? 0 : step - 1)}>Anterior</button> 
            )}
           <button 
            disabled={invalid} 
             onClick={() => {
              if (!invalid) {
                setStep(step === 14 ? 14 : step + 1);
                topRef.current.scrollIntoView({ behavior: "smooth" });
               }
               }}
            >
              Siguiente
          </button>
           {/*JSON.stringify(invalid.toString())*/}
          </div>
        )}

      </div>
    </div>
    </div>
    </div>
  );
  
};

const SuccessMessage = () => {
  return <h3>Tu cuestionario ha sido recibido, ¡Muchas gracias!</h3>;
};

export default TestForm;
