import React, { useEffect, useState } from 'react';
import LoginForm from './components/LoginForm';
import TestForm from './components/TestForm';
import axios from './utils/api';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [responses, setResponses] = useState([]);
  const [user, setUser] = useState(null);  // <-- Add this state variable

  // Fetch responses when component mounts
  useEffect(() => {
    axios
      .get('/api/responses')
      .then((response) => {
        setResponses(response.data.responses);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Handle login. It sets isLoggedIn state to true
  const handleLogin = (employee) => {
    //console.log("Employee data in handleLogin:", employee);
    setIsLoggedIn(true);
    setUser(employee);
  };
  
  // Depending on isLoggedIn state, show LoginForm or TestForm
  return (
    <div>
      {isLoggedIn ? (
        <TestForm responses={responses} user={user} />  // <-- Pass the user data to TestForm
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
